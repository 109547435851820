<template>
  <div>
    <el-card class="box-card">
      <div class="card">
        <div class="searchCriteria">
          <el-form :inline="true" :model="form">
            <el-form-item label="医院名称">
              <el-input placeholder="请输入医院名称"></el-input>
            </el-form-item>
            <el-form-item label="地区筛选">
              <el-input placeholder="请选择地区"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btnBox">
          <el-button class="btn" type="primary">查询</el-button>
          <el-button class="btn">重置</el-button>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: 20px;" class="box-card">
      <div class="tableCard">
        <div>
          <el-button @click="addHospital" type="primary">添加医院</el-button>
        </div>
        <div class="tableBox">
          <el-table :data="tableData" style="width: 100%" :height="470">
            <el-table-column align="center" prop="date" label="省份">
            </el-table-column>
            <el-table-column align="center" prop="name" label="市">
            </el-table-column>
            <el-table-column align="center" prop="address" label="区">
            </el-table-column>
            <el-table-column align="center" prop="address" label="医院名称">
            </el-table-column>
            <el-table-column align="center" prop="address" label="关联医生人数">
            </el-table-column>
            <el-table-column align="center" prop="address" label="关联患者人数">
            </el-table-column>
            <el-table-column align="center" prop="address" label="操作">
              <template #default="scope">
                <div class="linkBox">
                  <el-link
                    @click="editClick(scope.row)"
                    :underline="false"
                    type="primary"
                    >编辑</el-link
                  >
                  <el-link @click="deleteClick(scope.row)" :underline="false" type="primary"
                    >删除</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="->,total, sizes, prev, pager, next, jumper"
          :total="form.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <AddHospital v-model="dialogShow" @confirm="confirm" @cancel="cancel"></AddHospital>
    <DeleteHospital v-model="deleteDialog" @deleteEmit="deleteEmit" @deleteCancel="deleteCancel"></DeleteHospital>
  </div>
</template>
<script>
import AddHospital from "@/components/System/AddHospital.vue";
import DeleteHospital from "@/components/System/DeleteHospital.vue";

export default {
  name: "Hospital",
  components: {
    AddHospital,
    DeleteHospital
  },
  data() {
    return {
      form: {
        HospitalName: "",
        Area: "",
        pageNum: 1,
        pageSize: 10,
        total: 100,
      },
      dialogShow: false,
      deleteDialog:false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  
  mounted() {},
  methods: {
    // 添加医院
    addHospital() {
      this.dialogShow = true;
    },
    editClick(row) {
      console.log(row);
      this.dialogShow = true;
    },
    handleSizeChange(val) {
      console.log(val);
      
    },
    handleCurrentChange(val) {
      console.log(val);

    },
    confirm(){
        
        
      this.dialogShow = false;

    },
    cancel(){
        this.dialogShow = false;
    },
    deleteClick(row){
      console.log(row);

        this.deleteDialog = true;
    },
    deleteEmit(){
        this.deleteDialog = false;
    },
    deleteCancel(){
        this.deleteDialog = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  width: 100%;
  .card {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .btnBox {
      display: flex;
      gap: 5px;
      height: 40px;
      .btn {
      }
    }
  }
  .tableCard {
    .tableBox {
      margin-bottom: 10px;
      margin-top: 10px;
      .linkBox {
        display: flex;
        gap: 10px;
        justify-content: center;
      }
    }
  }
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 100px;
}
</style>
