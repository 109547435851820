<template>
  <div>
    <el-dialog title="系统提醒" :visible.sync="modelValue" width="480px">
      <template #default>
        <div class="dialogBox">
          <div class="title" >确认删除当前医院组织？</div>
          <div class="btn">
            <el-button @click="deleteClick" type="danger">删 除</el-button>
            <el-button @click="cancel">取 消</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "DeleteHospital",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods:{
    deleteClick(){
        this.$emit("deleteEmit");

    },
    cancel(){
        this.$emit("deleteCancel");

    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__header {
  background: #1a7cf2;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .el-dialog__title {
    color: #ffffff;
  }
  .el-dialog__headerbtn {
    top: 14px;
    .el-icon {
      color: #ffffff;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 10px !important;
}
.dialogBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .title{
        padding-top: 15px;
        padding-bottom: 30px;
        font-size: 16px;
        border-bottom: 1px solid #EBECF0;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
  .btn {
    display: flex;
    gap: 20px;
  }
}
</style>
