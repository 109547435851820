<template>
  <div>
    <el-dialog title="添加医院" :visible.sync="modelValue" width="572px" center>
        <div style=" padding: 20px 20px 10px 20px;">
      <div class="dialogBox">
        <el-form :model="form">
          <el-form-item label="所属省份">
            <el-select
              style="width: 85%;"
              v-model="form.value"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属城市">
            <el-select
              style="width: 85%;"
              v-model="form.value"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属地区">
            <el-select
              style="width: 85%;"
              v-model="form.value"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医院名称">
            <div>
              <el-input
                style="width: 85%;"
                v-model="form.input"
                placeholder="请输入"
              ></el-input>
              <div class="exist" >*医院名称已存在，请重新命名</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div  class="btn" >
        <el-button @click="cancel">取消</el-button>
        <el-button @click="confirm" type="primary">确认</el-button>
      </div>
    </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "AddHospital",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      form: {
        value: "",
        input: "",
      },
      
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods:{
    confirm(){
       

        this.$emit("confirm")
    },
    cancel(){
        this.$emit("cancel")

    }
  }
};
</script>
<style lang="scss" scoped>
.dialogBox {
  .exist{
    color: red;
    font-size: 14px;
    margin-left: 67px;
  }
}
.btn{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #D9D9D9;
    padding-top: 20px;
    margin-top: -15px;
}
</style>
